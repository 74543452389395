<template>
  <div id="uploadDocsPrefernce">
    <div
      v-loading="loading"
      :element-loading-text="loadingText"
      element-loading-spinner="el-icon-loading"
      element-loading-background="rgba(0, 0, 0, 0.8)"
    >
      <h3
        class="text-center text-dark fw-normal fs-4 mb-1"
        style="font-family: Inter"
      >
        Upload Request Documents
      </h3>
      <el-row class="block-types el-row-center-items select-your-preference">
        <el-col
          :xl="24"
          :lg="24"
          :md="24"
          :sm="24"
          :xs="24"
          style="text-align: center"
          class="select-your-preference-upload-file"
        >
          <div
            class="upload-file-container"
            style="width: 518px; height: 288px"
          >
            <el-upload
              class="upload-file-el-upload"
              drag
              name="logo"
              :on-change="uploadFile"
              action
              :show-file-list="false"
              :auto-upload="false"
              accept=".png, .jpg, .jpeg"
              multiple
              :disabled="isOneFileUploaded(files)"
            >
              <div class="upload-file">
                <div class="icon py-2">
                  <img
                    src="@/assets/img/icons/upload-file.svg"
                    alt="Upload File"
                    height="40"
                    width="100"
                  />
                </div>
                <h4 class="fw-normal blue pb-2">Upload A File</h4>
                <p class="text-dark fs-9 mb-0">Drag &amp; Drop files here</p>
                <p class="text-muted fs-9 opacity-60 upload-a-file-bottom-text">
                  File Supported: JPG,PNG
                </p>
              </div>
            </el-upload>
            <div class="upload-from-other-container">
              <p
                class="
                  text-muted
                  fs-9
                  opacity-60
                  upload-a-file-upload-from-text
                "
              >
                Upload From
              </p>
              <div class="from-others">
                <!-- <div class="image">
                  <el-tooltip
                    class="item"
                    effect="dark"
                    placement="bottom-start"
                    content="Blank Document"
                  >
                    <img
                      src="@/assets/img/icons/Blank.svg"
                      alt="Nimble logo"
                      class="upload-icon"
                      @click="chooseBlankDocument"
                    />
                  </el-tooltip>
                </div>
                <div class="icon">
                  <el-tooltip
                    class="item"
                    effect="dark"
                    placement="bottom-start"
                    content="Upload From Templates"
                  >
                    <img
                      src="@/assets/img/icons/templates.svg"
                      alt="Upload File"
                      height="28"
                      style="cursor: pointer"
                      @click="createTemplate"
                    />
                  </el-tooltip>
                </div> -->
                <div
                  class="image"
                  v-on:click="
                    (e) =>
                      handleUploadFrom(e, [
                        'googledrive',
                        'dropbox',
                        'onedrive',
                      ])
                  "
                >
                  <el-tooltip placeholder="top-start" content="Google Drive">
                    <img
                      src="@/assets/img/google-drive.svg"
                      alt="Nimble logo"
                      class="upload-icon"
                    />
                  </el-tooltip>
                </div>
                <div
                  class="image"
                  v-on:click="
                    (e) =>
                      handleUploadFrom(e, [
                        'dropbox',
                        'googledrive',
                        'onedrive',
                      ])
                  "
                >
                  <el-tooltip placeholder="top-start" content="Drop Box">
                    <img
                      src="@/assets/img/dropbox.svg"
                      alt="Nimble logo"
                      class="upload-icon"
                    />
                  </el-tooltip>
                </div>
                <div
                  class="image"
                  v-on:click="
                    (e) =>
                      handleUploadFrom(e, [
                        'onedrive',
                        'googledrive',
                        'dropbox',
                      ])
                  "
                >
                  <el-tooltip
                    class="item"
                    effect="dark"
                    placement="bottom-start"
                    content="One Drive"
                  >
                    <img
                      src="@/assets/img/onedrive.svg"
                      alt="Nimble logo"
                      class="upload-icon"
                    />
                  </el-tooltip>
                </div>
              </div>
            </div>
          </div>
          <span v-if="logoError">{{ logoError }}</span>
        </el-col>
        <!--<el-col :lg="12" :md="12" :sm="12" :xs="24" class="flex-center select-your-preference-choose-templates">
           <div class="upload-template">
            <router-link class="d-block" :to="{ path: '/templates' }">
              <el-button
                shadow="never"
                class="
                  white-space-break
                  w-100
                  h-100
                  px-4
                  py-5
                  text-center
                  bg-white
                "
              >
                <div class="icon py-4">
                  <img
                    src="@/assets/img/icons/templates.svg"
                    alt="Upload File"
                    height="40"
                  />
                </div>
                <h4 class="fw-normal blue pb-2">Choose from Templates</h4>
                <p class="text-dark fs-9 mb-0">
                  Choose from custom templates, and modify it however you want
                </p>
              </el-button>
            </router-link>
          </div>
        </el-col>-->
      </el-row>

      <el-row type="flex" :gutter="30" justify="center" class="name-types">
        <el-col :span="14">
          <draggable v-if="files && files.length" v-model="files">
            <transition-group tag="div" name="flip-list" id="field-group">
              <div
                class="filename"
                v-for="(file, index) in files"
                :key="JSON.stringify(file)"
              >
                <a class="el-upload-list__item-name">
                  <img
                    :src="require('@/assets/img/icons/upload-active.svg')"
                    alt="icon"
                    class="img-active"
                  />
                  <img
                    v-if="
                      file.raw.type == 'image/jpeg' ||
                      file.raw.type == 'image/jpg'
                    "
                    :src="require('@/assets/img/icons/JPG.svg')"
                    alt="icon"
                    class="img-fluid"
                  />
                  <img
                    v-else-if="file.raw.type == 'image/png'"
                    :src="require('@/assets/img/icons/PNG.svg')"
                    alt="icon"
                    class="img-fluid"
                  />

                  <img
                    v-else
                    :src="require('@/assets/img/icons/pdf.svg')"
                    alt="icon"
                    class="img-fluid"
                  />
                  {{ file.name }}
                </a>
                <div class="direction-buttons">
                  <el-button
                    type="text"
                    icon="el-icon-bottom"
                    class="directions"
                    @click="moveDown(index)"
                  >
                  </el-button>
                  <el-button
                    type="text"
                    icon="el-icon-top"
                    class="directions"
                    @click="moveUp(index)"
                  >
                  </el-button>
                  <a @click="deleteItem(index)">
                    <i class="el-icon-circle-close"></i>
                  </a>
                </div>
              </div>
            </transition-group>
          </draggable>
        </el-col>
      </el-row>
      <el-row
        type="flex"
        :gutter="30"
        justify="center"
        v-if="files && files.length > 1"
        class="mt-1"
      >
        <el-col :span="14">
          <el-checkbox v-model="isMergeDocument"
            >Process it as a single document?</el-checkbox
          >
        </el-col>
      </el-row>
      <el-row type="flex" :gutter="30" justify="center">
        <el-col :span="6" :offset="4">
          <div v-if="files && files.length && files.length > 0">
            <el-button
              type="danger"
              class="text-center upload-btn"
              @click="checkForMergeOrUpload"
              >Next</el-button
            >
          </div>
          <!-- <div v-if="isFileIsUploaded" class>
            <el-button
              v-if="isFileIsUploaded"
              class="text-center upload-btn"
              :loading="disabledButton"
              @click="addCompanyDocument"
              type="danger" 
            >Next</el-button>
          </div> -->
        </el-col>
      </el-row>
    </div>
    <el-dialog
      title="All Templates"
      :visible.sync="createDocFromTemplate"
      :width="getIsMobile ? '100%' : '50%'"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      :before-close="beforeClose"
      v-loading="loading"
    >
      <div>
        <span>Select a template</span>
      </div>
      <el-input
        class="search mt-1"
        placeholder="Search a template"
        v-model="search_string"
        clearable
        style="width: 100%"
      >
      </el-input>
      <el-scrollbar wrap-style="max-height:350px;padding:-20px">
        <el-table
          width="100%"
          ref="AllTempTable"
          :data="templates"
          highlight-current-row
          @current-change="handleCurrentChange"
        >
          <el-table-column
            min-width="696"
            :sort-method="titleSort"
            selectionMode="single"
            label="Name"
            id="name"
            sortable
            :default-sort="{ prop: 'name', order: 'descending' }"
          >
            <template slot-scope="scope">
              <div class="d-flex">
                <div class="icon-block">
                  <div class="icon">
                    <div class="image-file" v-if="isImage(scope.row.file_type)">
                      <img
                        src="@/assets/img/icons/image-file.svg"
                        alt="icon"
                        class="img-fluid"
                        style="width: 20px"
                      />
                    </div>
                    <div class="pdf-file" v-if="isPdf(scope.row.file_type)">
                      <img
                        src="@/assets/img/icons/pdf-file.svg"
                        alt="icon"
                        class="img-fluid"
                        style="width: 20px"
                      />
                    </div>
                    <div class="doc-file">
                      <img
                        v-if="
                          !isImage(scope.row.file_type) &&
                          !isPdf(scope.row.file_type)
                        "
                        src="@/assets/img/icons/doc-file.svg"
                        alt="icon"
                        class="img-fluid"
                        style="width: 20px"
                      />
                    </div>
                  </div>
                </div>
                <div v-if="scope.row.title" style="margin-left: 10px">
                  <a class="doc-title">
                    {{ getDocumentTitle(scope.row.title) }}
                  </a>
                </div>
                <div @click="handleSendDocumentToUsers(scope.row)"></div>
              </div>
            </template>
          </el-table-column>
          <!--<el-table-column >
        <img v-if="this.currentRow"
        src="@/assets/img/icons/check-circle-fill.svg"/>
    </el-table-column>-->
        </el-table>
      </el-scrollbar>
      <span slot="footer">
        <el-button class="mt-1" @click="close"> Cancel</el-button>
        <el-button type="danger" @click="goToSendDoc"> Continue </el-button>
      </span>
    </el-dialog>
    <el-dialog
      :visible.sync="blanckDocumentModal"
      title="Upload a Blank Document"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      :before-close="resetBlankTemplate"
      class="upload-a-blank-document-popup"
    >
      <div v-loading="blanckDocumentData.loading">
        <span>Enter Document name</span>
        <el-input
          placeholder="Enter Document name"
          v-model="blanckDocumentData.name"
          class="mb-2 mt-1"
        ></el-input>
        <span>Select number of pages</span>
        <el-select
          placeholder="Select number of pages"
          v-model="blanckDocumentData.pages"
          style="width: 100%"
          class="mt-1"
        >
          <el-option
            v-for="(i, index) in [1, 2, 3]"
            :key="index"
            :label="i"
            :value="i"
          ></el-option>
        </el-select>
        <div class="btn-wrapper">
          <el-button @click="resetBlankTemplate">Cancel</el-button>
          <el-button
            :disabled="!blanckDocumentData.name"
            type="primary"
            @click="uploadBlanckDocument"
            >Continue</el-button
          >
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
//import UserDocumentsHelper from "@/mixins/UserDocumentsHelper";
import { bus } from "../../main";
import draggable from "vuedraggable";
import appConfig from "@/config/app";
import axios from "@/config/axios";

// import Select from "../templates/formComponents/Select.vue";
export default {
  //mixins: [UserDocumentsHelper],
  name:"companyDocuments-UploadDocumentPreference",
  components: {
    draggable,
    // Select,
  },
  data() {
    return {
      card_type: "",
      ext: "",
      // _validFileExtensions : ["jpg", "jpeg", "bmp", "doc", "png","docx","pdf","txt"],
      uploadFromOthersFileName: "",
      uploadFromOthersBlobData: null,
      loadingText: "Fetching data",
      fieldsFormUpload: {
        title: "",
        type: "CUSTOM",
        file_ref_id: "",
        selectedFile: "",
        selectedFileName: "",
      },
      disabledButton: false,
      dialogVisible: false,
      loading: false,
      logoError: "",
      pdfData: "",
      pdfDoc: null,
      authid: null,
      fileUploadUrl: "",
      fileUploadUrls: [],
      fileNames: [],
      fileUploadData: "",
      isMergeDocument: false,
      selectTemp: null,
      files: [],
      templates: [],
      isFileIsUploaded: false,
      showCheckFill: false,
      // dialogVisible: false,
      blanckDocumentModal: false,
      createDocFromTemplate: false,
      search_string: "",
      allTemp: "",
      currentRow: null,
      blanckDocumentData: {
        name: "",
        pages: 1,
        loading: false,
      },
      blankTemplatesUrl: [
        "/rest/esignature/files/3cc3b9ac-ff3d-4dfa-90a9-96644c719a15.pdf",
        "/rest/esignature/files/dfc42fc5-4696-4e88-81df-8daf1a7a63dd.pdf",
        "/rest/esignature/files/56647615-6ccc-49c6-9991-b72be2706148.pdf",
      ],
      // blankTemplatesUrl:['/blankTemplate/uploadDir/7dc799ac-f7c1-4740-844e-82c7e9bed010.pdf','/blankTemplate/uploadDir/21acb043-a936-4399-93c8-65e987361905.pdf','/blankTemplate/uploadDir/8edab929-dfde-47ba-adf7-c00d63520a27.pdf']
    };
  },
  computed: {
    ...mapGetters("auth", ["getAuthenticatedUser", "getActiveWorkspace"]),
    ...mapGetters("documents", [
      "getAllTemplates",
      "getFileUploadURL",
      "getCompanyDocumentAddStatus",
      "getNewCompanyDocument",
      "getCreateConfigureDocumentData",
      "getDraftDocumentCreateStatus",
      "getDraftDocumentCreateData",
      "getDocumentSaveAsTemplate",
    ]),
    ...mapGetters("configurableDocuments", ["getConfigurableDocumentDataById"]),
    ...mapGetters("employeeDocuments", [
      "getAuthorizedRepresentativeSignatureStatus",
      "getDocumentUserSignatureStatus",
      "getDocumentApprovalStatus",
      "getDocumentCompletedAfterApproval",
    ]),
    ...mapGetters("s3FileUpload", ["getDocumentUploadStatus"]),
    ...mapGetters("auth", ["getAuthenticationDetails"]),
    ...mapGetters("fileUpload", [
      "getUploadFileDataStatus",
      "getUploadedPdfData",
      "getCopyDocumentStatus",
      "getCopyDocumentUrl",
    ]),
    ...mapGetters("navigationOpen", [
      "getIsOpen",
      "getIsCollapse",
      "getIsMobile",
    ]),
    uploadHeaders() {
      return {
        Authorization: this.getAuthenticationDetails.access_token,
      };
    },
    isImage() {
      return (type) => (type && type.indexOf("image") > -1 ? true : false);
    },
    isPdf() {
      return (type) => (type && type.indexOf("pdf") > -1 ? true : false);
    },
    isOneFileUploaded() {
      return (files) => (files.length >= 1 ? true : false);
    }

  },
  // beforeDestroy() {
  //   this.clearFormData();
  // },
  async mounted() {
    // await this.fetchTemplates();
    // this.authid =
    //   this.getAuthenticatedUser && this.getAuthenticatedUser._id
    //     ? this.getAuthenticatedUser._id
    //     : null;
    
    if (this.$route && this.$route.query && this.$route.query.card_type) {
      this.card_type = this.$route.query.card_type;
    }
  },
  methods: {
    close() {
      this.$emit("close", {});
      this.createDocFromTemplate = false;
    },
    // getDocumentTitle(title) {
    //   if (title.length > 100) {
    //     return title.slice(0, 98) + "...";
    //   }
    //   return title;
    // },
    getDocumentTitle(title) {
      if (title.length > 40) {
        return title.slice(0, 38) + "...";
      }
      return title;
    },
    async createTemplate() {
      //
      this.createDocFromTemplate = true;
      this.loading = true;
      this.fetchTemplates();
      this.configurable_document_id;
    },
    async uploadBlanckDocument() {
      this.loading = true;
      this.blanckDocumentData.loading = true;
      this.fieldsFormUpload.title = this.blanckDocumentData.name;
      let selectedFileUrl =
        this.blankTemplatesUrl[this.blanckDocumentData.pages - 1];
      let url = selectedFileUrl.split("/");
      let document_url_path = "";
      if (url[1] != process.env.VUE_APP_APP_TYPE) {
        document_url_path = `/${process.env.VUE_APP_APP_TYPE}${selectedFileUrl}`;
      } else {
        document_url_path = selectedFileUrl;
      }
      let companyId =
        this.getActiveWorkspace && this.getActiveWorkspace.company_id
          ? this.getActiveWorkspace.company_id
          : this.getAuthenticatedUser._id;
      let params = {
        companyId: companyId,
        pdfContent: appConfig.S3_BUCKET_URL + document_url_path,
      };
      await this.$store.dispatch(
        "fileUpload/duplicateUploadedDocumentv4",
        params
      );
      if (this.getCopyDocumentUrl) {
        let tempUrl = "";
        if (this.getCopyDocumentUrl.url.includes(appConfig.S3_BUCKET_URL)) {
          tempUrl = this.getCopyDocumentUrl.url.replace(
            appConfig.S3_BUCKET_URL,
            ""
          );
        } else {
          tempUrl = this.getCopyDocumentUrl.url;
        }
        this.fileUploadUrl = tempUrl;//
        this.fileUploadUrls = [tempUrl];
        this.fileNames = [this.blanckDocumentData.name];
        this.disabledButton = false;
        this.isFileIsUploaded = true;
        this.blanckDocumentData.loading = false;
        this.addCompanyDocument();
      } else {
        this.logoError = "Error in uploading file. Please try again..";
      }
      // let url = this.fileUploadUrl.split("/");
      // let id = url[url.length - 1].split(".");
      // await this.$store.dispatch("fileUpload/fetchUploadedDocumentv1",id[0]);
      // if(this.getUploadedPdfData){
      //   this.fileUploadData = this.getUploadedPdfData.content
      //   this.addCompanyDocument();
      // }else{
      //   this.logoError = "Error in uploading file. Please try again..";
      // }
      this.blanckDocumentModal = false;
    },
    resetBlankTemplate() {
      this.blanckDocumentModal = false;
      this.blanckDocumentData.name = "";
      this.blanckDocumentData.pages = 1;
    },
    chooseBlankDocument() {
      this.blanckDocumentModal = true;
    },
    FileListItems(files) {
      // var b = new ClipboardEvent("").clipboardData || new DataTransfer()
      var b = new DataTransfer();
      for (var i = 0, len = files.length; i < len; i++) b.items.add(files[i]);
      return b.files;
    },
    setCurrent(row) {
      this.$refs.AllTempTable.setCurrentRow(row);
    },
    handleCurrentChange(val) {
      this.currentRow = val;
    },
    beforeClose(done) {
      this.dialogVisible = false;
      done();
    },
    prepareFieldsData(elements) {
      let data = {};
      elements.forEach((element) => {
        if (element.type == "HEADING" || element.type == "PARAGRAPH") {
          data[element.selected_tag] = element.content;
        } else if (element.value) {
          data[element.key] = element.value;
        } else if (element.source) {
          data[element.key] = element.source;
        } else {
          data[element.key] = "";
        }
      });
      return data;
    },
    async fetchTemplates() {
      try {
        let params = { get_all: true };
        if (this.search_string) {
          params.search_string = this.search_string;
        }
        await this.$store.dispatch("documents/fetchAllTemplates", params);
        //get_all: true,
        this.templates = this.getAllTemplates.data;
        this.templates =
          this.getAllTemplates && this.getAllTemplates.data
            ? this.getAllTemplates.data
            : [];
        this.total =
          this.getAllTemplates && this.getAllTemplates.total
            ? this.getAllTemplates.total
            : 0;
        let configureDocumentIds = this.templates.flatMap(
          (temp) => temp.configurable_document_id
        );
        await this.getEmployeeDocumentsCount(configureDocumentIds);
        this.loading = false;
      } catch (err) {
        this.loading = false;
      }
    },
    async handleSendDocumentToUsers(data) {
      try {
        this.loading = true;
        await this.$store.dispatch(
          "configurableDocuments/fetchConfigurableDocumentById",
          { id: data.configurable_document_id }
        );

        let shareTemplate = false;
        if (data.user_id.toString() != this.authid.toString()) {
          shareTemplate = true;
        }
        this.createDraft(data._id, shareTemplate);
      } catch (err) {
        console.log("handleSendDocumentToUsers",err);
      }
    },
    async createDraft(document_id, shareTemplate) {
      try {
        let companyDocumentData = this.getConfigurableDocumentDataById;
        let documentData = this.prepareFieldsData(
          companyDocumentData.pages[0].fields
        );
        let params = {
          document_template_id: document_id,
          document_users: companyDocumentData.document_users,
          enforce_signature_order: companyDocumentData.enforce_signature_order,
          document_data: documentData,
          companayDocumentData: companyDocumentData,
          is_document: false,
          configurabledocumentid: this.getConfigurableDocumentDataById,
          isShareTemplate: shareTemplate,
        };
        await this.$store.dispatch("documents/createDraftDocument", params);

        if (this.getDraftDocumentCreateStatus) {
          let draftId = this.getDraftDocumentCreateData.data._id;
          this.$router.push({
            name: "employee-documents-custom-document-edit",
            params: {
              employee_document_id: draftId,
            },
          });
        }
      } catch (err) {
        console.log("createDraft",err);
      }
    },
    goToSendDoc() {
      if (this.currentRow) {
        this.handleSendDocumentToUsers(this.currentRow);
      } else if (!this.currentRow) {
        this.$message.error("Please choose a document to continue");
      }
    },
    composeNewFileItem(file) {
      let fileItem = {};
      fileItem.name = file.name;
      fileItem.percentage = 0;
      fileItem.raw = file;
      fileItem.size = file.size;
      fileItem.status = "ready";
      fileItem.uid = file.uid;
      return fileItem;
    },
    toDataUrl(url, callback) {
      var xhr = new XMLHttpRequest();
      xhr.onload = function () {
        callback(xhr.response);
      };
      xhr.open("GET", url);
      xhr.responseType = "blob";
      xhr.send();
    },
    handleUploadDoneFromOthers(res) {
      if (
        res.filesUploaded.length > 0 &&
        res.filesUploaded[0].url !== undefined
      ) {
        this.uploadFromOthersBlobData = null;
        this.uploadFromOthersFileName = res.filesUploaded[0].filename;
        this.loading = true;
        var self = this;
        this.toDataUrl(res.filesUploaded[0].url, function (x) {
          self.uploadFromOthersBlobData = x;
          self.loading = false;

          self.files.push(
            new self.composeNewFileItem(
              new File(
                [self.uploadFromOthersBlobData],
                self.uploadFromOthersFileName
              )
            )
          );
        });
      }
    },
    handleUploadFrom(e, fromSources) {
      if(this.isOneFileUploaded(this.files) >= 1) {
        return;
      }
      const client = require("filestack-js").init("AOI3ejHzTcuVrrWTbhHeaz");
      const options = {
        fromSources: fromSources,
        accept: [
          "*.png",
          "*.jpg",
          "*.jpeg",
        ],
        allowManualRetry: true,
        transformations: {
          crop: false,
        },
        customText: {
          "Select Files to Upload": "Select Image to Upload",
        },
        onUploadDone: (res) => this.handleUploadDoneFromOthers(res),
      };
      client.picker(options).open();
    },
    moveDown(index) {
      if (index == this.files.length - 1) {
        return;
      }

      let sortedUsers = this.swapSingatureUsers(index, index + 1, this.files);
      this.files = [];
      this.files = sortedUsers;
    },
    moveUp(index) {
      if (index == 0) {
        return;
      }

      let sortedUsers = this.swapSingatureUsers(index, index - 1, this.files);
      this.files = [];
      this.files = sortedUsers;
    },
    swapSingatureUsers(fromIndex, toIndex, signaturedUsers) {
      let fromUser = signaturedUsers[fromIndex];
      let toUser = signaturedUsers[toIndex];
      signaturedUsers[fromIndex] = toUser;
      signaturedUsers[toIndex] = fromUser;

      return signaturedUsers;
    },
    async deleteItem(index) {
      this.files.splice(index, 1);
    },
    checkForMergeOrUpload() {
      this.uploadSingleFile();
      // if (this.isMergeDocument && this.files && this.files.length > 1) {
      //   this.mergeAllDocs();
      // } else {
      //   this.uploadSingleFile();
      // }
    },
    async uploadSingleFile() {
      try {
        this.loading = true;
        var formData = new FormData();
        this.files.forEach((file, index) => {
          if (index == 0) {
            this.fieldsFormUpload.selectedFile =
              file.raw !== undefined ? file.raw : file;
            this.fieldsFormUpload.selectedFileName =
              file.raw !== undefined ? file.raw.name : file.name;
            this.fieldsFormUpload.title = file.name;
          }
          formData.append(
            "uploadedFiles",
            file.raw !== undefined ? file.raw : file
          );
        });
        let companyId =
          this.getActiveWorkspace && this.getActiveWorkspace.company_id
            ? this.getActiveWorkspace.company_id
            : this.getAuthenticatedUser._id;
        // let params = {
        //   data: formData,
        //   companyId: companyId,
        // };
        formData.append(
          "companyId",
          companyId
        );
        formData.append(
          "card_type",
          this.card_type,
        );
        // this.currentImage = this.$refs.file.files.item(0);
        this.loading = true;
        const res = await axios.post("/request-documents/upload-files", formData);

        if (res.data.success) {
            this.$notify.success({
              title: "Success",
              message: "Document uploaded successfully",
            });
            this.loading = false;
            this.$router.push({
              name: "request-documents-edit",
              params: {
                request_document_id: res.data.data._id,
              },
            });
        } else {
          this.loading = false;
          this.$notify.error({
            title: "Error",
            // message: this.getBenefitErrors.critical_error
            message: "Document uploading failed.",
          });
          this.files = [];
        }

      } catch (err) {
        console.log('>>> uploadSingleFile() -> catch (err): ',err);
        this.loading = false;
      }
    },
    async mergeAllDocs() {
      try {
        this.loading = true;
        var formData = new FormData();
        this.files.forEach((file, index) => {
          if (index == 0) {
            this.fieldsFormUpload.selectedFile =
              file.raw !== undefined ? file.raw : file;
            this.fieldsFormUpload.selectedFileName =
              file.raw !== undefined ? file.raw.name : file.name;
            this.fieldsFormUpload.title = file.name;
          }
          formData.append(
            "uploadedFiles",
            file.raw !== undefined ? file.raw : file
          );
        });
        let companyId =
          this.getActiveWorkspace && this.getActiveWorkspace.company_id
            ? this.getActiveWorkspace.company_id
            : this.getAuthenticatedUser._id;
        let params = {
          data: formData,
          companyId: companyId,
        };
        await this.$store.dispatch("fileUpload/uploadUserDocxFilev4", params);
        this.loading = false;
        if (this.getUploadFileDataStatus) {
          let tempUrl = "";
          if (
            this.getUploadFileDataStatus.url.includes(appConfig.S3_BUCKET_URL)
          ) {
            tempUrl = this.getUploadFileDataStatus.url.replace(
              appConfig.S3_BUCKET_URL,
              ""
            );
          } else {
            tempUrl = this.getUploadFileDataStatus.url;
          }
          this.fileUploadUrl = tempUrl;
          this.fileUploadUrls = [tempUrl];
          this.fileNames = [this.fieldsFormUpload.title];
          this.disabledButton = false;
          this.isFileIsUploaded = true;
          this.addCompanyDocument();
        } else {
          this.logoError = "Error in uploading file. Please try again..";
        }
      } catch (err) {
        this.loading = false;
      }
    },
    async addCompanyDocument() {
      try {
        this.loading = true;
        let params = this.prepareParams();

        if (!this.fileUploadUrl) {
          this.$message.error("Please select a document");
          return;
        }

        await this.$store.dispatch("documents/addCompanyDocument", params);
        if (this.getCompanyDocumentAddStatus) {
          await this.uploadSelectedDocument();

          // bus.$emit("document-added", this.fieldsFormUpload.type);
          // this.$emit("document-added", this.fieldsFormUpload.type);
        }
        this.loading = false;
      } catch (err) {
        this.loading = false;
      }
    },
    titleSort(a, b) {
      if (a.title.toUpperCase() < b.title.toUpperCase()) return -1;
      if (a.title.toUpperCase() > b.title.toUpperCase()) return 1;
      return 0;
    },
    prepareParams() {
      let params = {};
      params.file_upload_url = this.fileUploadUrl;
      params.file_upload_urls = this.fileUploadUrls;
      params.file_names = this.fileNames;
      params.title = this.fieldsFormUpload.title;
      params.type = this.fieldsFormUpload.type;
      return params;
    },

    // prepareParams() {
    //   let params = {};
    //   // params.files = formData;
    // if(this.blanckDocumentData.name){
    //   params.title = this.blanckDocumentData.name;
    //   params.type = 'CUSTOM';
    //   this.fileUploadUrl = this.blankTemplatesUrl[this.blanckDocumentData.pages - 1]
    //   let url = this.fileUploadUrl.split("/");
    //   let id = url[url.length - 1].split(".");
    //   params.fileId = id[0];
    //   params.isBlank = true;
    //   this.resetBlankTemplate();
    // }else{
    //   params.file_upload_url = this.fileUploadUrl;
    //   params.title = this.fieldsFormUpload.title;
    //   params.type = this.fieldsFormUpload.type;
    // }

    //   if(this.files){
    //     let formData = new FormData();
    //     this.files.forEach((file, index) => {
    //       if (index == 0) {
    //         this.fieldsFormUpload.selectedFile =
    //           file.raw !== undefined ? file.raw : file;
    //         this.fieldsFormUpload.selectedFileName =
    //           file.raw !== undefined ? file.raw.name : file.name;
    //         this.fieldsFormUpload.title =
    //           file.raw !== undefined ? file.raw.name : file.name;
    //         params.title = this.fieldsFormUpload.title;
    //       }
    //       formData.append(
    //         "uploadedFiles",
    //         file.raw !== undefined ? file.raw : file
    //       );
    //     });
    //     formData.append("title",(params.title)?params.title:this.fieldsFormUpload.title);
    //     formData.append("fileId",(params.fileId)?params.fileId:'');
    //     formData.append("isBlank",(params.isBlank)?params.isBlank:false);
    //     params = formData;
    //   }
    //   return params;
    // },

    handleClose() {
      this.dialogVisible = false;
    },
    async beforeLogoUpload(file) {
      console.log(file);
    },
    uploadFile(file) {
      if (
        file.raw.type != "audio/mpeg" &&
        file.raw.type != "video/mp4" &&
        file.raw.type != "image/gif" &&
        file.raw.type != "text/html" &&
        file.raw.type != "text/css" &&
        file.raw.type != "text/javascript" &&
        file.raw.type != "application/json" &&
        file.raw.type != "" &&
        file.raw.type != "application/x-msdownload"
      ) {
        if (file.size < 25000000) {
          file.name = file.name.includes('.') && file.name.split(".")[0] ? file.name.split(".")[0] : file.name;
          this.files.push(file);
        } else {
          this.$message.error(
            "Large file. Maximum upload file size: 25 MB"
          );
        }
      } else {
        this.$message("File format is not supported");
      }
    },
    async uploadUserFile(file) {
      var formData = new FormData();
      formData.append("uploadedFiles", file.raw);
      if (
        file.raw.type == "application/pdf" ||
        file.raw.type == "image/jpeg" ||
        file.raw.type == "image/png" ||
        file.raw.type == "image/jg"
      ) {
        await this.$store.dispatch("fileUpload/uploadUserFile", formData);
      } else if (
        file.raw.type ==
          "application/vnd.openxmlformats-officedocument.wordprocessingml.document" ||
        file.raw.type == "application/msword"
      ) {
        await this.$store.dispatch("fileUpload/uploadUserDocxFilev1", formData);
      }

      this.loading = false;
      if (this.getUploadFileDataStatus) {
        this.fileUploadUrl = this.getUploadFileDataStatus.url;
        this.disabledButton = false;
      } else {
        4;
        this.logoError = "Error in uploading file. Please try again..";
      }
    },
    async documentFileupload() {
      let params = this.prepareParams();
      await this.$store.dispatch("documents/addCompanyDocument", params);
      if (this.getCompanyDocumentAddStatus) {
        if (this.getNewCompanyDocument) {
          await this.$store.dispatch(
            "documents/fetchConfigureDocumentIdByDocumentId",
            this.getNewCompanyDocument._id
          );
          if (this.getCreateConfigureDocumentData) {
            bus.$emit("document-added", this.fieldsFormUpload.type);
            this.$emit("document-added", this.fieldsFormUpload.type);
            this.$notify.success({
              title: "Success",
              message: "Document created successfully",
            });
            this.$router.push({
              name: "edit-configure-document-image",
              params: {
                document_id: this.getNewCompanyDocument._id,
                configurable_document_id:
                  this.getCreateConfigureDocumentData.configurable_document._id,
              },
              query: this.$route.query,
            });
          } else {
            this.$message("Sorry ! Error while creating configurable document");
          }
        } else {
          this.$message("Sorry ! Error while file uploading");
        }
      }
    },

    async getFileUploadUrl(file) {
      let data = {
        file_name: file.raw.name,
        file_type: file.raw.type,
        file_size_in_kb: file.raw.size / 1000,
      };
      await this.$store.dispatch("documents/fetchDocumentUploadUrl", data);
    },
    async uploadSelectedDocument() {
      this.loading = true;
      this.loadingText = "Uploading Document...";
      if (this.getNewCompanyDocument) {
        await this.$store.dispatch(
          "documents/fetchConfigureDocumentIdByDocumentId",
          this.getNewCompanyDocument._id
        );
        if (this.getCreateConfigureDocumentData) {
          this.$notify.success({
            title: "Success",
            message: "Document created successfully",
          });
          this.$router.push({
            name: "edit-configure-document-image",
            params: {
              document_id: this.getNewCompanyDocument._id,
              configurable_document_id:
                this.getCreateConfigureDocumentData.configurable_document._id,
            },
            query: this.$route.query,
          });
        } else {
          this.$message("Sorry ! Error while creating configurable document");
        }
      } else {
        this.$message("Sorry ! Error while file uploading");
      }
      this.loading = false;
    },
    cancel() {
      this.$emit("document-cancelled");
    },
    clearFormData() {
      this.fieldsFormUpload = {
        title: "",
        description: "",
        type: "",
        category: "",
        groups: [],
        file_ref_id: "",
        selectedFile: "",
        selectedFileName: "",
        e_signature_value: "",
        e_signature: "",
      };
    },
    /* watch: {
    search_string: function (val) {
      this.search_string = val;
      this.fetchTemplates();
    }
      },*/
  },
  watch: {
    search_string: function (val) {
      this.search_string = val;
      this.fetchTemplates();
    },
  },
  beforeDestroy() {
    this.$store.commit(
    "documents/setFileUploadURL",null,{root:true})
    this.$store.commit("documents/setDraftDocumentCreateStatus",null,{root:true})
    this.$store.commit("documents/setCompanyDocumentAddStatus",null,{root:true})
  //  this.$store.commit("documents/setNewCompanyDocument",null,{root:true})
    this.$store.commit("documents/setCreateConfigureDocumentData",null,{root:true})
    this.$store.commit("documents/setAllTemplates",null,{root:true})
    this.$store.commit("documents/setDraftDocumentCreateData",null,{root:true})
    this.$store.commit("documents/setDocumentSaveAsTemplate",null,{root:true})
    this.$store.commit("s3FileUpload/setDocumentUploadStatus",null,{root:true})
   //this.$store.commit( "configurableDocuments/setConfigurableDocumentDataById",null,{root:true})
    this.$store.commit("employeeDocuments/setAuthorizedRepresentativeSignatureStatus",null,{root:true})
    this.$store.commit("employeeDocuments/setDocumentUserSignatureStatus",null,{root:true})
    this.$store.commit("employeeDocuments/setDocumentApprovalStatus",null,{root:true})
    this.$store.commit("employeeDocuments/setDocumentCompletedAfterApproval",null,
     {root: true,}
    );
    this.clearFormData();
  },
};
</script>

<style lang="scss">
.mobile {
  #uploadDocsPrefernce {
    .name-types .filename .direction-buttons {
      left: 85%;
    }
    .flex-center {
      display: flex;
      justify-content: center;
    }
    .el-upload-dragger {
      width: 200px !important;
      padding: 5px 5px 19px 5px !important;
      margin-top: 0px !important;
    }
    .block-types {
      // .upload-file {
      //   width: 200px;
      //   height: 100%;
      // }
      .upload-template {
        width: 200px;
        height: 213px;
        .icon {
          padding-top: 0px !important;
          margin-top: 0px !important;
        }
        .el-button {
          padding: 0px !important;
          height: 220px !important;
        }
        .d-block {
          height: 220px;
        }
      }
    }
  }
}
.doc-modal {
  .el-dialog__header {
    .el-dialog__title {
      text-transform: uppercase;
    }
  }
  .error {
    color: red;
  }
}
#uploadDocsPrefernce {
  .el-dialog__body {
    overflow-y: hidden;
    scrollbar-width: none !important;
    .el-scrollbar__wrap {
      overflow-x: hidden;
    }
  }
  .upload-template {
    width: 358px;
    height: 300px;
    .icon {
      margin-top: -56px;
    }
    .d-block {
      height: 300px;
    }
  }
  padding-top: 2%;
  .block-types {
    .upload-file {
      height: 262px;
      // .el-upload.el-upload--text {
      //   height: inherit;
      //   width: 100%;
      //   display: flex;
      //   justify-content: flex-end;
      // }
    }
    .d-block {
      .el-button {
        background-color: #efefef;
        // max-width: 360px;
        &:hover {
          h4 {
            color: #f754a2;
          }
          border-color: #f754a2;
        }
      }
    }
  }
  .name-types {
    margin-top: 2%;
    .filename {
      height: 56px;
      margin-top: 1%;
      cursor: pointer;
      background: #ffffff;
      border: 1px solid #dfe3eb;
      box-sizing: border-box;
      border-radius: 2px;
      .el-upload-list__item-name {
        margin-top: 1em;
        .img-active {
          visibility: hidden;
          width: 6px !important;
          margin-right: 10px;
          margin-left: 10px;
          position: relative;
          top: 3px;
        }
        .img-fluid {
          position: relative;
          top: 5px;
          width: 18.43px !important;
        }
      }
      // .delete-doc {
      //   visibility: hidden;
      // }
      .direction-buttons {
        display: flex;
        visibility: hidden;
        position: relative;
        top: -35%;
        left: 97%;
        .directions {
          position: relative;
          right: 10%;
          top: -10px !important;
        }
      }
      &:hover {
        .img-active {
          visibility: visible;
          width: 6px !important;
          margin-right: 10px;
          margin-left: 10px;
          position: relative;
          top: 3px;
        }
        .direction-buttons {
          display: flex;
          visibility: visible;
          position: relative;
          top: -35%;
          left: 97%;
          .directions {
            position: relative;
            right: 10%;
            top: -10px !important;
          }
        }
        // .delete-doc {
        //   visibility: visible;
        //   position: relative;
        //   top: -60%;
        //   left: 97%;
        // }
      }
    }
  }
  .upload-btn {
    margin-top: 10px;
    position: relative;
    bottom: 0px;
    align-items: center;
  }
  // .el-upload-dragger {
  //   border: 1px solid #dcdfe6;
  //   max-width: 100%;
  //   height: inherit;
  //   padding-top: 2.5em;
  //   &:hover {
  //     h4 {
  //       color: #f754a2;
  //     }
  //     border-color: #f754a2;
  //   }
  // }
  @media (max-width: 991.98px) {
    .el-row-center-items {
      .el-col {
        margin-bottom: 20px;
        text-align: center !important;
        .el-upload {
          justify-content: center !important;
        }
        .d-block {
          justify-content: center !important;
          display: flex !important;
        }
      }
    }
    .upload-btn {
      margin-left: -30px;
    }
  }

  .select-your-preference-upload-file {
    display: flex;
    justify-content: center;
    .upload-file-container {
      &:hover {
        border: 1px solid #f754a2;
      }
      @media (min-width: 991.98px) {
        min-height: 336px;
      }
      // max-width: 360px;
      border: 1px solid #dcdfe6;
      border-radius: 4px;
      .upload-file-el-upload {
        .block-types {
          .upload-file {
            width: 200px;
            height: 100%;
          }
        }
        .upload-a-file-bottom-text {
          border-bottom: 2px solid #ddd3d3;
          padding: 0 0 10px 0;
          margin: 0px 10px;
        }
        @media (max-width: 991.98px) {
          .el-upload-dragger {
            height: 207px !important;
          }
        }
        .el-upload-dragger {
          border: none;
          width: 360px;
          margin-top: 40px;
        }
      }
      .upload-from-other-container {
        text-align: center;
        .el-upload {
          border: none !important;
          border-radius: 6px;
          cursor: pointer;
          position: relative;
          overflow: hidden;

          &:hover {
            border: none;
          }
        }
        .upload-a-file-upload-from-text {
          margin-top: -30px;
        }
        .from-others {
          display: flex;
          justify-content: center;
          .image {
            cursor: pointer;
            margin-left: 10px;
            margin-right: 10px;
            .upload-icon {
              width: 25px;
              height: 25px;
            }
            @media (max-width: 991.98px) {
              margin-bottom: 16px;
            }
          }
        }
      }
    }
  }
  .upload-template {
    .d-block {
      min-height: 336px;
    }
  }
  .select-your-preference {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
}
#uploadDocsPrefernce {
  .upload-a-blank-document-popup {
    // max-width: 530px;
    .btn-wrapper {
      display: flex;
      margin-top: 20px;
      margin-bottom: 18px;
      justify-content: right;
    }
    @media (max-width: 700px) {
      width: 100%;
      // @media (max-width: 600px) {
      //   width: 95%;
      //   @media (max-width: 500px) {
      //     width: 100%;
      //   }
      // }
      .el-dialog {
        width: 90%;
      }
    }
    .el-dialog {
      max-width: 600px;
    }
  }
}
</style>